<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных предложения
      </h4>
      <div class="alert-body">
        Не найдено предложения с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'catalog'}"
        >
          Каталог
        </b-link>
        и выбрать другое.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row
        align-v="center"
        no-gutters
      >
        <b-col cols="auto">
          <b-avatar
            :src="get(product, 'images[0].path')"
            rounded="sm"
            :alt="product.name"
            size="75px"
            icon="ImageIcon"
            variant="light-secondary"
            class="mr-1"
          >
            <span
              v-if="!get(product, 'images[0].path')"
              class="d-flex align-items-center"
            >
              <feather-icon
                icon="ImageIcon"
                size="16"
              />
            </span>
          </b-avatar>
        </b-col>
        <b-col>
          <div class="text-secondary">
            <small>{{ product.code }}</small>
          </div>
          <div><strong>{{ product.name }}</strong></div>
        </b-col>
      </b-row>
      <b-row
        v-if="product.description"
        class="mt-1"
      >
        <b-col>
          {{ product.description }}
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <div class="">
        <b-row
          v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')"
          class="mt-1"
        >
          <b-col>
            <div class="d-flex flex-wrap justify-content-end">
              <b-button
                v-if="item.id"
                variant="primary"
                :to="{
                  name: 'offers.edit',
                  params: {
                    id: item.id,
                  }
                }"
              >
                Изменить
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteItem(item)"
              >
                Удалить
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Активность"
              label-for="is-active"
            >
              <b-badge
                class="offer-card__label"
                :variant="item.is_active ?'success' : 'secondary'"
                size="sm"
                pill
              >
                <template v-if="item.is_active">
                  Вкл
                </template>
                <template v-else>
                  Выкл
                </template>
              </b-badge>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Внешний id"
              label-for="external_public_id"
              description="Идентификатор торгового предложения в системе пользователя. Уникально в рамках проекта."
            >
              <b-form-input
                id="external_public_id"
                :value="item.external_public_id"
                placeholder=""
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Закупочная цена"
              label-for="purchase_price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="purchase_price"
                  :value="item.purchase_price"
                  placeholder="Закупочная цена"
                  type="number"
                  lang="ru"
                  readonly
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Основная цена"
              label-for="price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="price"
                  :value="item.price"
                  placeholder="Основная цена"
                  type="number"
                  lang="ru"
                  readonly
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Акционная цена"
              label-for="sale_price"
            >
              <b-input-group
                append="₽"
              >
                <b-form-input
                  id="sale_price"
                  :value="item.sale_price"
                  placeholder="Акционная цена"
                  type="number"
                  lang="ru"
                  readonly
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Описание"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                :value="item.description"
                placeholder="Введите описание"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="item.ym_suggestions_prices && item.ym_suggestions_prices.length"
            cols="12"
          >
            <strong>Рекомендованные цены:</strong><br>
            <div
              v-for="(rPrice, rPriceIndex) in item.ym_suggestions_prices"
              :key="rPrice.type + '' + rPriceIndex"
              class="small"
            >
              {{ rPrice.type }}: {{ rPrice.price.toLocaleString() + ' ₽' }}
            </div>

          </b-col>
        </b-row>

        <offer-integrations
          mode="view"
          :yandex-market-fbs="item.yandex_market_fbs"
          :yandex-market-dbs="item.yandex_market_dbs"
          :merlion-fresh-ymfbs="item.merlion_fresh_ymfbs"
          :merlion-ymdbs="item.merlion_ymdbs"
        />

        <b-row
          v-if="item.website_content"
          class="mt-1"
        >
          <b-col>
            <b-form-group
              label="RichContent"
              label-for="website_content"
            >
              <mavon-editor
                ref="meditor"
                v-model="item.website_content"
                language="ru"
                :subfield="false"
                default-open="preview"
                :editable="false"
                :toolbars-flag="false"
                :navigation="false"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-if="$store.getters['workingMode/checkPermission']('manage_product_offers')"
          class="mt-1"
        >
          <b-col>
            <div class="d-flex flex-wrap justify-content-end">
              <b-button
                v-if="item.id"
                variant="primary"
                :to="{
                  name: 'offers.edit',
                  params: {
                    id: item.id,
                  }
                }"
              >
                Изменить
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deleteItem(item)"
              >
                Удалить
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <warehouse-remains
      v-if="item"
      :offer="item"
      @update-remain="runAfterUpdateRemain"
    />

    <ProductInfo :product="product" />
  </b-overlay>
</template>

<script>
import {
  BAlert, BButton, BCard, BCol, BFormGroup, BFormInput, BLink, BOverlay, BRow,
  BFormTextarea, BInputGroup, BAvatar,
  BBadge,
} from 'bootstrap-vue'
import { get } from 'lodash'
import { getCurrentInstance, ref } from 'vue'
import { getProductOffer as getOffer, deleteProductOffer } from '@/services/main-api/shop/product-offers'
import { getProduct as getProductApi } from '@/services/main-api/catalog/products/products'
import useCrudShow from '@/composables/useCrudShow'
import OfferIntegrations from '@/modules/offers/components/OfferIntegrations.vue'
import useIntegrations from '@/composables/useIntegrations'
import ProductInfo from '@/modules/offers/components/ProductInfo.vue'
import WarehouseRemains from '../components/WarehouseRemains.vue'

export default {
  name: 'EditCompanyView',
  components: {
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormTextarea,
    BInputGroup,
    BAvatar,
    BBadge,
    WarehouseRemains,
    OfferIntegrations,
    ProductInfo,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    const product = ref({})
    const getProduct = async id => {
      try {
        const res = await getProductApi({ id, includes: 'images;files;seo' })
        product.value = res.data.data
      } catch (e) {
        product.value = {}
      }
    }

    const { items: integrations, getItems: getIntegrations } = useIntegrations()

    const getIntegrationsData = async () => {
      await getIntegrations()
      integrations.value.forEach(integration => {
        const type = integration.type.split('-').join('_')
        if (!instance.item[type].find(i => i.id === integration.id)) {
          instance.item[type].push({
            id: integration.id,
            enabled: false,
          })
        }
      })
    }

    const getItem = async () => {
      try {
        const res = await getOffer({ id: props.id, includes: 'product_warehouse_remains;product_warehouse_remains_with_logs' })
        const resP = res.data.data
        if (resP.website_content === null) {
          resP.website_content = ''
        }

        instance.item = resP
        await Promise.all([
          getProduct(res.data.data.product_id),
          getIntegrationsData(),
        ])
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        project_id: null,
        product_id: null,
        price: null,
        sale_price: null,
        purchase_price: null,
        is_active: false,
        description: '',
        yandex_market_fbs: [],
        yandex_market_dbs: [],
        merlion_fresh_ymfbs: [],
        merlion_ymdbs: [],
        external_public_id: '',
        website_content: '',
      },
      getItem,
      deleteEntity: i => deleteProductOffer(i.id),
      getQuestionTextBeforeDeletion: () => 'Предложение будет удалено',
      getTextAfterDeletion: () => 'Предложение было удалено.',
      runAfterDeletion: () => {
        instance.$router.go(-1)
      },
    })

    const runAfterUpdateRemain = async () => {
      loading.value = true
      await getItem()
      loading.value = false
    }

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      get,

      product,
      runAfterUpdateRemain,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
  },
}
</script>

<style lang="scss" scoped>
.a-md-section{
  padding: 20px;
  background: #fff;
  img{
    max-width: 100%;
  }
  &__md{

  }
}
</style>
